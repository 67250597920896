import { Popover, Transition } from "@headlessui/react";
import DateRangeIcon from "@material-design-icons/svg/filled/date_range.svg";
import ArrowIcon from "@material-design-icons/svg/outlined/keyboard_arrow_down.svg";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import classNames from "classnames";
import dayjs, { Dayjs as DayjsType } from "dayjs";
import React, { useState } from "react";
import { useId } from "react-aria";

import CustomRangePickerDay from "~/components/core/datepickers/CustomRangePicker/CustomRangePickerDay";
import DatePicker from "~/components/core/datepickers/DatePicker";
import { menuTransitionClasses } from "~/components/layouts/AccountLayout/AccountHeader/AccountUser/UserMenu";
import { DATE_UNIT } from "~/constants/dates";
import { DateOrNull, Range } from "~/declarations/filters";

import { Button } from "../Button";
import { WeekStartAwareDayjsUtils } from "../datepickers/CustomRangePicker/WeekStartAwareDayjsUtils";
import { ISelectOption } from "../FormsCommon";

interface IDatePickerProps {
  selectedOption: ISelectOption;
  onChange: (value: string) => void;
  options: ISelectOption[];
  handleChangeCustomPeriod: (range: Range) => void;
  initialRange?: Range;
  rangeFilter?: Range;
  schoolYearRange?: Range;
}

export const SelectDates: React.FC<IDatePickerProps> = ({
  selectedOption,
  onChange,
  options,
  handleChangeCustomPeriod,
  initialRange,
  rangeFilter,
  schoolYearRange
}) => {
  const [begin, setBegin] = useState<DateOrNull>(null);
  const [end, setEnd] = useState<DateOrNull>(null);
  const [currentMonth, setCurrentMonth] = useState<DayjsType>(dayjs());
  const [hovered, setHovered] = useState<MaterialUiPickersDate | null>(null);

  React.useEffect(() => {
    setBegin(initialRange && initialRange[0] ? dayjs(initialRange[0]) : null);
    setEnd(initialRange && initialRange[1] ? dayjs(initialRange[1]) : null);
  }, [initialRange]);

  const handleSubmit = () => {
    handleChangeCustomPeriod([
      begin,
      end?.add(1, "day").subtract(1, "millisecond") ?? null
    ]);
  };

  const handleSetCustomRange = (date: DayjsType) => {
    if (begin && end) {
      setBegin(date);
      setEnd(null);
    } else if (!begin) {
      setBegin(date);
    } else if (!end) {
      if (date.isBefore(begin)) {
        setEnd(begin);
        setBegin(date);
      } else {
        setEnd(date);
      }
    }
  };

  const handleClose = () => {
    if (end) {
      setCurrentMonth(dayjs(end));
    }
  };

  const handleSetHover = (date: MaterialUiPickersDate) => () => {
    begin && !end && setHovered(date);
  };

  const handleSelectMonth = (date: MaterialUiPickersDate) => {
    date && setCurrentMonth(dayjs(date));
  };

  const handleNextMonth = () => {
    const nextMonth = currentMonth.add(1, DATE_UNIT.month);
    setCurrentMonth(nextMonth);
  };

  const handlePrevMonth = () => {
    const prevMonth = currentMonth.subtract(1, DATE_UNIT.month);
    setCurrentMonth(prevMonth);
  };

  const prevMonth = currentMonth.subtract(1, DATE_UNIT.month);
  const disableSubmit = Boolean(!begin || !end);

  let dateFilterOptions = options;
  if (selectedOption.value !== "custom") {
    dateFilterOptions = options.filter(option => option.value !== "custom");
  }

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <SelectDateButton
            open={open}
            label={selectedOption.label}
            rangeFilter={rangeFilter}
            schoolYearRange={schoolYearRange}
          />

          <Transition as={React.Fragment} {...menuTransitionClasses}>
            <Popover.Panel
              className={classNames(
                "absolute z-10 border border-cream-300",
                "bg-white rounded rounded-t-none outline-none shadow-down top-[70px] left-[-1px]",
                "flex flex-col sm:flex-row",
                "sm:w-[538px] h-[650px] sm:h-[490px]"
              )}
            >
              <div className="w-full sm:w-[200px] flex flex-col gap-[16px] justify-between p-[24px] paragraph">
                <div className="flex flex-row sm:flex-col flex-wrap sm:flex-nowrap gap-[24px]">
                  {dateFilterOptions.map(option => (
                    <Button
                      variant="wrapper"
                      action={() => {
                        close();
                        handleClose();
                        onChange(option.value);
                      }}
                      key={option.value}
                      className={classNames(
                        "hover:underline w-[45%] sm:w-fit text-left",
                        option.value === selectedOption.value &&
                          "font-semibold underline"
                      )}
                      disabled={option.value === "custom"}
                      // data-testid={testidOption?.(option.value).option}
                    >
                      {option.label}
                    </Button>
                  ))}
                </div>
                <Button
                  variant="primary"
                  className="mx-auto sm:mx-0"
                  action={() => {
                    close();
                    handleClose();
                    handleSubmit();
                  }}
                  disabled={disableSubmit}
                >
                  Apply dates
                </Button>
              </div>

              <div className="flex justify-start flex-grow overflow-x-hidden overflow-y-scroll border-l sm:justify-center hideScrollbars border-cream-300">
                <div className="max-w-[425px]">
                  <MuiPickersUtilsProvider utils={WeekStartAwareDayjsUtils}>
                    <div className="flex pt-[12px]">
                      <div>
                        <DatePicker
                          renderDay={(
                            date,
                            selectedDate,
                            dayInCurrentMonth
                          ) => (
                            <CustomRangePickerDay
                              onClick={handleSetCustomRange}
                              onFocus={handleSetHover(date)}
                              date={date}
                              beginDate={begin}
                              endDate={end}
                              dayInCurrentMonth={dayInCurrentMonth}
                              hoveredDate={hovered}
                              disableFuture={false}
                            />
                          )}
                          onSelectMonth={handleSelectMonth}
                          date={prevMonth}
                          month={currentMonth}
                          onBack={handlePrevMonth}
                          onNext={handleNextMonth}
                          withControls
                        />
                        <DatePicker
                          renderDay={(
                            date,
                            selectedDate,
                            dayInCurrentMonth
                          ) => (
                            <CustomRangePickerDay
                              onClick={handleSetCustomRange}
                              onFocus={handleSetHover(date)}
                              date={date}
                              beginDate={begin}
                              endDate={end}
                              dayInCurrentMonth={dayInCurrentMonth}
                              hoveredDate={hovered}
                              disableFuture={false}
                            />
                          )}
                          date={currentMonth}
                          month={currentMonth}
                        />
                      </div>
                    </div>
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

const SelectDateButton: React.FC<{
  open: boolean;
  label: string;
  rangeFilter?: Range;
  schoolYearRange?: Range;
}> = ({ open, label, rangeFilter, schoolYearRange }) => {
  const buttonId = useId();
  const labelId = useId();
  const range = schoolYearRange ? schoolYearRange : rangeFilter;
  return (
    <>
      <label id={labelId} htmlFor={buttonId} className="inputLabel">
        Date range
      </label>
      <div className={classNames("flex mt-[8px]", open && "sm:shadow-default")}>
        <Popover.Button
          as="div"
          tabIndex={0}
          className={classNames(
            "formInput formInputBorderSimpleStyle flex !p-0",
            open && "rounded-b-none"
          )}
          id={buttonId}
        >
          <div className="dateSelect w-[200px] p-[8px] flex items-center justify-between">
            <span className="max-w-[152px] truncate">{label}</span>
            <ArrowIcon
              aria-hidden
              className="w-[24px] h-[24px] pointer-events-none transition duration-200 ui-open:rotate-180"
            />
          </div>
          <div className="dateDisplay whitespace-nowrap w-[336px] p-[8px] border-l border-cream-300 flex gap-[8px] items-center">
            <DateRangeIcon
              className="w-[24px] h-[24px] fill-current"
              aria-hidden
            />
            {range &&
              `${range[0]?.format("MMM D, YYYY")} - ${range[1]?.format(
                "MMM D, YYYY"
              )}`}
          </div>
        </Popover.Button>
      </div>
    </>
  );
};
